import './misc/google-tag-manager';
import './misc/navigation';
import './misc/socials';
import './misc/sticky';
import '../scss/style.scss';

// require jQuery normally
const $ = require('jquery');

global.$ = global.jQuery = $;
