export function loadImages(selector) {
  let images = document.querySelectorAll(selector);
  images.forEach(img => {
    img.src = img.dataset.src;
    img.srcset = img.dataset.srcset;
    img.classList.remove('lazy-placeholder');
    img.classList.remove('lazy');
    img.classList.remove('js-lazy-force-img');
  });
}
