onWithPrecondition = function (callback) {
  var isDone = false;
  return function (e) {
    if (isDone === true) {
      isDone = false;
      return;
    }
    e.preventDefault();
    callback.apply(this, arguments);
    isDone = true;
    $(this).trigger(e.type);
  }
};


function addGTMListenerById(id, dataLayerBody) {

  let item = $(id);
  if (item.length) {
    item.each(function () {
      $(this).bind("click", function () {
        dataLayer.push(dataLayerBody);
      });
    })
  }
}

function addMultipleGTMTags(id, event) {
  let item = $(id);
  if (item.length) {
    item.each(function () {
      $(this).bind("click", function () {
        let filterName =  '' + $(this).text().replace(/\s/g, '');
        dataLayer.push({
          'event': '' + event,
          'Filtre': filterName,
        });
      });
    })
  }
}

document.addEventListener('DOMContentLoaded', (event) => {

  // Filtres Inspirations et conseils
  addMultipleGTMTags('.gtm_ranges', 'Filtre Inspirations');
  addMultipleGTMTags('.gtm_advices', 'Filtre Conseils');

  // Boutons sticky
  addGTMListenerById('#get_brochure_link', {
    "event": "Formulaire Sticky",
    "CTA": "catalogue",
  });
  addGTMListenerById('#get_devis_link', {
    "event": "Formulaire Sticky",
    "CTA": "devis",
  });

  // Homepage trouver professionnel
  addGTMListenerById('#homepage_store_around', {
    "event": "Trouver un Professionnel",
    "CTA": "autour de moi",
  });
  addGTMListenerById('#homepage_store_search', {
    "event": "Trouver un Professionnel",
    "CTA": "recherche",
  });

  // Newsletter
  addGTMListenerById('#small_newsletter button', {
    "event": "Inscription Newsletter",
    "CTA": "inscription",
  });
  addGTMListenerById('#big_newsletter button', {
    "event": "Inscription Newsletter",
    "CTA": "validation",
  });

  // Partage reseaux sociaux
  addGTMListenerById('#twitter-share-button', {
    "event": "Partage",
    "Share": "twitter"
  });
  addGTMListenerById('#fb-share-button', {
    "event": "Partage",
    "Share": "facebook"
  });
  addGTMListenerById('#pinterest-share-button', {
    "event": "Partage",
    "Share": "pinterest"
  });
  addGTMListenerById('#copy-share-button', {
    "event": "Partage",
    "Share": "copier le lien"
  });

  // Demande de devis
  // Acces a l'etape 2
  addGTMListenerById('*[data-target="stepOne"] label', {
    "event": "VirtualPageview",
    "virtualURL": "/devis-gratuit/collections",
    "virtualTitle": "Devis Gratuit - Gammes de produits qui vous intéressent",
  });
  //Acces a l'etape 3
  addGTMListenerById('*[data-goto="stepThree"]', {
    "event":"VirtualPageview",
    "virtualURL":"/devis-gratuit/projet",
    "virtualTitle" : "Devis Gratuit - Précisez votre projet"
  });
  //Acces a l'etape 4
  addGTMListenerById('*[data-goto="stepFour"]', {
    "event":"VirtualPageview",
    "virtualURL":"/devis-gratuit/adresse",
    "virtualTitle" : "Devis Gratuit - Votre adresse",
  });
  //Envoie form
  addGTMListenerById('#quote_request_submit', {
    "event":"VirtualPageview",
    "virtualURL":"/devis-gratuit/confirmation",
    "virtualTitle" : "Devis Gratuit - Confirmation de votre demande"
  });

  // page Magasin
  addGTMListenerById('#load-more-realisations', {
    "event": "Store Locator",
    "CTA": "réalisations",
  });

  // Bandeau promo promotional-button
  addGTMListenerById('#promotional-button', {
    "event": "Offre Promotionnelle",
    "CTA": "profiter",
  });

  if ($('#downloadSummary').length) {
    dataLayer.push({
      "event":"VirtualPageview",
      "virtualURL":"/devis-gratuit/confirmation",
      "virtualTitle" : "Devis Gratuit - Confirmation de votre demande"
    });
  }

});


