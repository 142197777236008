import $ from 'jquery';

let exports = new Object();

// Helpers responsive
exports.BREAKPOINTS = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
};

exports.isSM = function () {
  return $(document).width() < exports.BREAKPOINTS.sm;
};

exports.isMD = function () {
    return $(document).width() >= exports.BREAKPOINTS.sm && $(document).width() < exports.BREAKPOINTS.md;
};

exports.isLG = function () {
    return $(document).width() >= exports.BREAKPOINTS.md && $(document).width() < exports.BREAKPOINTS.lg;
};

exports.isXL = function () {
    return $(document).width() >= exports.BREAKPOINTS.lg && $(document).width() < exports.BREAKPOINTS.xl;
};

exports.isXXL = function () {
    return $(document).width() >= exports.BREAKPOINTS.xl;
};

exports.isMobile = function () {
    return $(document).width() < exports.BREAKPOINTS.md;
}

exports.isMobileLarge = function () {
    return $(document).width() < exports.BREAKPOINTS.lg;
}

exports.isDesktop = function () {
    return $(document).width() >= exports.BREAKPOINTS.md;
}

exports.isDesktopLarge = function () {
    return $(document).width() >= exports.BREAKPOINTS.lg;
}

// exports.isDesktopXLarge = function () {
//     return $(document).width() >= exports.BREAKPOINTS.xl;
// }

export default exports;
