/**
 * Scripts relative to the navigation
 */

import breakpoints from './breakpoints';
import $ from "jquery";
import { loadImages } from '../misc/images';
require('bootstrap');

// boolen pour passer du mobile au dekstop 1 seule fois au rezise
let switchFlag;
const $menulvl1 = $('[data-role="menu-lvl1-btn"]');
const $menulvl2 = $('[data-role="menu-lvl2-btn"]');
const $back = $('[data-role="back-lvl"]');
const $menuItems = document.querySelectorAll('[data-role="menu-lvl1"]');

function initNav() {
  // si mobile
  if (breakpoints.isMobileLarge()) {
    // si le mobile n'a pas été initialisé
    if (switchFlag !== true) {
      switchFlag = true;
      $('[data-expand]').on("click", function() {

        // on charge les images du menu au clique sur le bouton du menu
        loadImages('.js-menu-img');

        // close menu -> on ferme tous les panneaux
        if($(this).hasClass('active')) {
          $('[data-panel]').removeClass('is-active');
          document.body.classList.remove('menu-open');
        } else {
          document.body.classList.add('menu-open');
        }
        $(this).toggleClass('active');
        $('[data-display]').toggle().toggleClass('active');
        $('[data-role="menu-icon"]').toggleClass('active');
      });

      $back.click(function(){
        $(this).closest('[data-panel]').removeClass('is-active');
      });

      // Menu click niveau 1
      $menulvl1.on("click", function (e) {
        e.preventDefault();
        $(this).closest('[data-role="menu-lvl1"]').find('[data-role="menu-lvl1-container"]').addClass('is-active');
      });

      // Menu click niveau 2
      $menulvl2.on("click", function (e) {
        e.preventDefault();
        $(this).closest('[data-role="menu-lvl2"]').find('[data-role="menu-lvl2-container"]').addClass('is-active');
      });

      $('[data-display]').removeAttr('style').removeClass('active');
      $('[data-role="menu-icon"]').removeClass('active');

    $('[data-role="collapse"]').removeClass('dont-collapse-sm');

    }
  } else if (switchFlag !== false) {
    // si desktop et si première initialisation ou si on viens du mobile

    switchFlag = false;
    // on force les images du menu
    loadImages('.js-menu-img');
    $('[data-expand]').off('click');
    $('[data-panel]').removeClass('is-active');

    $('[data-display]').removeAttr('style').removeClass('active');
    $('[data-role="menu-icon"]').removeClass('active');

      $('[data-role="collapse"]').addClass('dont-collapse-sm');

    // ipad ? on active le touchpad
    // L'option 'passive' est ajoutée pour amélioration de l'audit SEO, critère : "La page utilise des écouteurs d'événements passifs pour améliorer les performances de défilement"
    $menuItems.forEach(item => {
      item.addEventListener("touchstart", function(){
        $menuItems.forEach(item => {
          item.classList.remove('is-hover');
        });
        this.classList.add('is-hover');
      }, {passive: true})
    });

    // ipad on ferme le menu si on clique en dehors
    // L'option 'passive' est ajoutée pour amélioration de l'audit SEO, critère : "La page utilise des écouteurs d'événements passifs pour améliorer les performances de défilement"
    document.addEventListener('touchstart', (event) => {
      if(!event.target.closest('[data-panel]') && !event.target.closest('[data-header]')) {
        $menuItems.forEach(item => {
          item.classList.remove('is-hover');
        });
      }
    }, {passive: true});
  }
}

//initialisation de la nav
$(document).ready(function () {
  initNav();
});

// Window resize
window.addEventListener("resize", function () {
  initNav();
});
