document.addEventListener('DOMContentLoaded', function () {
  // ajouter le service custom kwanko
  addKwankoService();

  (tarteaucitron.job = tarteaucitron.job || []).push('facebook');
  (tarteaucitron.job = tarteaucitron.job || []).push('twitter');
  (tarteaucitron.job = tarteaucitron.job || []).push('pinterest');
  (tarteaucitron.job = tarteaucitron.job || []).push('kwanko');

  // Facebook
  let fbButton = document.getElementById('fb-share-button');
  let url = window.location.href;

  if (fbButton !== null) {
    fbButton.addEventListener('click', function () {
      window.open('https://www.facebook.com/sharer/sharer.php?u=' + url,
        'facebook-share-dialog',
        'width=800,height=600',
      );
      return false;
    });
  }

  // Twitter
  let getWindowOptions = function () {
    let width = 500,
      height = 350,
      left = (window.innerWidth / 2) - (width / 2),
      top = (window.innerHeight / 2) - (height / 2);

    return [
      'resizable,scrollbars,status',
      'height=' + height,
      'width=' + width,
      'left=' + left,
      'top=' + top,
    ].join();
  };

  let twtBtn = document.querySelectorAll('.twitter-share-button-icon'),
    textToShare = document.querySelector('title'),
    text = encodeURIComponent(textToShare.textContent), // Récupère le contenu de la balise <title>
    locationHref = encodeURIComponent(location.href),
    shareUrl = 'https://twitter.com/intent/tweet?url=' + locationHref + '&text=' + text;

  twtBtn.forEach((btn) => {
    // Remplace l'url du btn par l'url avec paramètres de partage
    btn.href = shareUrl; // 1

    // Au clic, ouvre une fenêtre avec le tweet prérempli (Titre + URI de la page)
    btn.addEventListener('click', function (e) {
      e.preventDefault();
      let win = window.open(shareUrl, 'ShareOnTwitter', getWindowOptions());
      win.opener = null; // 2
    });
  });
});

function addKwankoService() {
  tarteaucitron.services.kwanko = {
    'key': 'kwanko',
    'type': 'other',
    'name': 'Kwanko',
    'uri': 'https://www.kwanko.com/fr/',
    'needConsent': true,
    'cookies': [],
  };

}

