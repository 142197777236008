/**
 * Initialize sticky.
 *
 * Trigger sticky :
 * <div data-role="sticky-trigger" data-sticky_absolute='["mobile,desktop"]>
 *  <div data-role="sticky-container">
 *  </div>
 * </div>
 *
 * @returns {void}
 */
import breakpoints from './breakpoints';

const triggers = document.querySelectorAll('[data-role="sticky-trigger"]')

let stickyFunction = function () {

  let scroll = window.scrollY;
  // for every trigger
  triggers.forEach(trigger => {

    let stickyElement = trigger.querySelector('[data-role="sticky-container"]');
    let offset = trigger.offsetTop - stickyElement.offsetHeight;
    let isAbsoluteMobile = false;
    let isAbsoluteDekstop = false;

    // the calcul is not the same between absolute and relative position
    // if absolute not add padding to the body and not add the height of the element
    if(JSON.parse(trigger.dataset.sticky_absolute).includes('desktop')) {
      if(breakpoints.isDesktopLarge()) {
        isAbsoluteDekstop = true;
        offset = trigger.offsetTop;
      }
    }
    if(JSON.parse(trigger.dataset.sticky_absolute).includes('mobile')) {
      if(breakpoints.isMobileLarge()) {
        isAbsoluteMobile = true;
        offset = trigger.offsetTop;
      }
    }

    // if the scroll > trigger position
    if (scroll > trigger.offsetTop) {
      stickyElement.classList.add('is-sticky');
      trigger.classList.add('is-sticky');
      //add the height of the sticky to the body
      if((!isAbsoluteDekstop && breakpoints.isDesktopLarge()) || (!isAbsoluteMobile && breakpoints.isMobileLarge())) {
        document.body.style.paddingTop = `${stickyElement.offsetHeight}px`;
      }

      // scroll up or down
      if(this.oldScroll > scroll) {
        stickyElement.classList.add('is-show');
      } else {
        stickyElement.classList.remove('is-show');
      }

    } else if (scroll <= offset) {
      // remove the sticky
      stickyElement.classList.remove('is-sticky');
      stickyElement.classList.remove('is-show');
      trigger.classList.remove('is-sticky');
      if(!isAbsoluteDekstop || !isAbsoluteMobile) {
        document.body.style.removeProperty('padding-top');
      }
    }
  });

  this.oldScroll = scroll;
};

function initializeSticky() {
  $(window).off('scroll', stickyFunction).on('scroll', stickyFunction);
}

// sticky
document.addEventListener('DOMContentLoaded', function() {
  initializeSticky();
})

// Resize de la fenêtre
window.addEventListener("resize", function() {
  initializeSticky();
});
